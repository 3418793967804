import { API_URL } from '@/constants/config';
import axios from 'axios';

export class ProgramsApi {
  async getAllPrograms(limit?: number) {
    try {
      const response = await axios.get(
        `${API_URL}/admission_programs?depth=5${limit ? `&limit=${limit}` : ''}`
      );
      console.log('response on get programs Api', response);
      if (response.status === 200) {
        console.log('response.data', response.data.docs);
        return response.data;
      }
    } catch (error) {
      console.error('Unexpected error:', error);
      throw error;
    }
  }
  async getCampusPrograms(campusId: string) {
    try {
      const response = await axios.get(
        `${API_URL}/admission_programs?depth=2&where[program.campus_id][equals]=${campusId}`
      );
      if (response.status === 200) {
        console.log('response.data', response.data.docs);
        return response.data;
      }
    } catch (error) {
      console.error('Unexpected error:', error);
      throw error;
    }
  }
  async getProgram(programId: string) {
    try {
      const response = await axios.get(
        `${API_URL}/admission_programs/${programId}?depth=5`
      );
      console.log('response on get single program Api', response);
      if (response.status === 200) {
        console.log('response.data', response.data.docs);
        return response.data;
      }
    } catch (error) {
      console.error('Unexpected error:', error);
      throw error;
    }
  }
}

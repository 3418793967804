import { API_URL } from '@/constants/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
export const programsApi = createApi({
  reducerPath: 'programsApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    getPrograms: builder.query({
      query: ({ page = 1 }) => `admission_programs?depth=5&page=${page}`
    })
  })
});

export const { useGetProgramsQuery } = programsApi;

import { ProgramsApi } from '@/endpoints/programs';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const fetchPrograms = createAsyncThunk(
  'admission/fetchPrograms',
  async (campusId: string, { rejectWithValue }) => {
    const programsApi = new ProgramsApi();
    try {
      const response = await programsApi.getCampusPrograms(campusId);
      return response?.docs || [];
    } catch (error) {
      return rejectWithValue('Failed to fetch programs');
    }
  }
);

const initialState = {
  campusId: null,
  programId: null,
  admissionId: null,
  applicationId: null,
  programs: [],
  loading: false,
  error: ''
};

const admissionSlice = createSlice({
  name: 'admission',
  initialState,
  reducers: {
    setApplicationId: (state, action) => {
      state.applicationId = action.payload;
    },
    setCampusId: (state, action) => {
      state.campusId = action.payload;
    },
    setProgramId: (state, action) => {
      state.programId = action.payload;
    },
    setAdmissionId: (state, action) => {
      state.admissionId = action.payload;
    },
    clearCampusId: (state) => {
      state.campusId = null;
    },
    clearProgramId: (state) => {
      state.programId = null;
    },
    clearAdmissionId: (state) => {
      state.admissionId = null;
    },
    clearApplicationId: (state) => {
      state.applicationId = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPrograms.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchPrograms.fulfilled, (state, action) => {
        state.loading = false;
        state.programs = action.payload;
      })
      .addCase(fetchPrograms.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  }
});

export const {
  setCampusId,
  setAdmissionId,
  setProgramId,
  clearAdmissionId,
  clearProgramId,
  clearCampusId,
  setApplicationId,
  clearApplicationId
} = admissionSlice.actions;

export default admissionSlice.reducer;
